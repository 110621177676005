var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"relative flex p-2 outline-none cursor-pointer border rounded-lg duraation-200 transition-colors",class:{
			'pr-8 pl-4': _vm.buttonClicked,
			'hover:border-gray-900': _vm.surfer.noShow === null,
			'hover:border-red-900 bg-red-200': _vm.surfer.noShow === true,
			'hover:border-green-900 bg-green-200': _vm.surfer.noShow === false
		},attrs:{"type":"button"},on:{"click":function($event){return _vm.noShow()}}},[_c('div',{staticClass:"flex items-center space-x-2 font-semibold uppercase tracking-wider text-gray-600"},[_c('p',{class:{ 'opacity-50': _vm.surfer.noShow === true }},[_vm._v(" Attended ")]),_c('div',{staticClass:"relative w-14 h-6 flex-none flex items-center"},[_c('div',{staticClass:"w-full h-4 bg-gray-200 rounded-full"}),_c('div',{staticClass:"absolute top-0 left-0 w-6 h-6 rounded-full border bg-white transition-all duration-300",class:{
						'left-0': _vm.surfer.noShow === false,
						'left-4': _vm.surfer.noShow === null,
						'left-8': _vm.surfer.noShow === true
					}})]),_c('p',{class:{ 'opacity-50': _vm.surfer.noShow === false }},[_vm._v(" No Show ")])]),_c('span',{staticClass:"loader animation-spin h-3 w-3 bg-transparent absolute opacity-0 box-border transition-all duration-500 ease-in-out mx-auto rounded-full",class:{ 'opacity-100': _vm.buttonClicked }})])
}
var staticRenderFns = []

export { render, staticRenderFns }