<template>
	<button
		class="
      relative flex p-2 
      outline-none cursor-pointer 
      border rounded-lg 
      duraation-200 transition-colors
    "
		v-bind:class="{
			'pr-8 pl-4': buttonClicked,
			'hover:border-gray-900': surfer.noShow === null,
			'hover:border-red-900 bg-red-200': surfer.noShow === true,
			'hover:border-green-900 bg-green-200': surfer.noShow === false
		}"
		v-on:click="noShow()"
		type="button"
	>
		<div
			class="
        flex items-center space-x-2 
        font-semibold uppercase tracking-wider text-gray-600
      "
		>
			<p v-bind:class="{ 'opacity-50': surfer.noShow === true }">
				Attended
			</p>

			<!-- - Toggler - -->
			<div class="relative w-14 h-6 flex-none flex items-center">
				<!-- - Toggler Background - -->
				<div class="w-full h-4 bg-gray-200 rounded-full"></div>

				<!-- - Toggler Dot - -->
				<div
					class="
            absolute top-0 left-0 w-6 h-6
            rounded-full border bg-white
            transition-all duration-300
          "
					v-bind:class="{
						'left-0': surfer.noShow === false,
						'left-4': surfer.noShow === null,
						'left-8': surfer.noShow === true
					}"
				></div>
			</div>

			<p v-bind:class="{ 'opacity-50': surfer.noShow === false }">
				No Show
			</p>
		</div>

		<span
			class="loader animation-spin h-3 w-3 bg-transparent absolute opacity-0 box-border transition-all duration-500 ease-in-out mx-auto rounded-full"
			v-bind:class="{ 'opacity-100': buttonClicked }"
		></span>
	</button>
</template>

<script>
	/* eslint max-len: ["error", { "ignoreUrls": true }] */
	/* eslint max-len: ["error", { "ignoreComments": true }] */
	/* eslint max-len: ["error", { "ignoreStrings": true }] */
	/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
	/* eslint max-len: ["error", { "code": 300 }] */
	/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
	/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["bar"] }] */

	/*eslint no-param-reassign: ["error", { "props": false }]*/
	export default {
		props: {
			surfer: {
				type: Object,
				default: () => {},
				required: false
			}
		},
		data: () => ({
			buttonClicked: false
		}),
		// Component Methods:
		methods: {
			// Wave Check In Button Specific Functionality
			async noShow() {
				if (this.surfer.noShow === null) this.surfer.noShow = false;
				else this.surfer.noShow = !this.surfer.noShow;

				this.$restfulAPIService.updateResource(
					"waivers",
					this.surfer.uuid,
					this.surfer
				);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.loader {
		// Off-Piste Un-Tailwind-esque Bodges
		right: 0.875rem;
		top: 50%;
		// Loader Construction Using Borders
		border-top: 2px solid #fff;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		border-right: 2px solid rgba(#fff, 0.35);
	}

	.animation-spin {
		animation-name: spin;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes spin {
		from {
			transform: translateY(-50%) rotate(0deg);
		}
		to {
			transform: translateY(-50%) rotate(360deg);
		}
	}
</style>
