<template>
	<div>
		<div
			v-if="show"
			class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex my-5"
		>
			<div class="relative w-auto my-6 mx-auto max-w-6xl h-full">
				<!--content-->
				<div
					class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
				>
					<!--header-->
					<div
						class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t"
					>
						<h3 class="text-3xl font-semibold">
							The Wave Acknowledgement of Risk and Waiver
						</h3>
						<button
							class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
							v-on:click="close"
						>
							<span
								class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
							>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
								</svg>
							</span>
						</button>
					</div>
					<!--body-->
					<div class="relative p-6 flex-auto">
						<p class="mb-2 font-bold">
							Please Read Carefully Before Completing:
						</p>
						<p class="mb-2">
							All participants must complete this acknowledgement of risk and waiver before participating in
							surfing, body boarding or any other activity in the lake at The Wave. If you will be under 18
							years old on the day of your booked activity, the acknowledgement of risk and waiver must be
							completed by your parent or legal guardian. We will keep your completed waiver on file for 60
							days. Once the 60 day period has passed, if we update our safety or risk information, or if any of
							your circumstances change, you will be required to complete a new waiver. If there is anything
							that you do not understand please ask a member of The Wave Staff.
						</p>
						<h3>Acknowledgement Of Risk</h3>
						<p class="mb-2">
							Surfing and bodyboarding are physically challenging and carry risks that we cannot entirely
							eliminate, including damage to property, personal injury and in extremely rare circumstances,
							even death. You will reduce these risks by following The Wave Safety Rules set out below and all
							the instructions given to you by The Wave Staff.
						</p>
						<p class="mb-2">
							The Wave can produce a variety of waves of different speeds, shapes and sizes, which allows us
							to provide a safe environment for all abilities, from beginners to professionals. Information
							about the types of waves in each session and any specific risks or safety rules relating to the
							session are available on our website. The sessions are graded by ability. It is your
							responsibility to read the session descriptions and to ensure that you are competent to take
							part safely in your chosen session. If, during a session, we feel that you are not competent and
							may put yourself or others at risk, we reserve the right to remove you from the session without
							refund or compensation.
						</p>
						<p class="mb-2">
							We strongly advise that you begin with the easier sessions and work your way up. If you have any
							questions at all about the level of skill required for a session, please ask a member of The
							Wave Staff or contact our Customer Service Team.
						</p>
						<h3>Disclaimer</h3>
						<ul class="list-disc my-5 ml-5">
							<li>
								<p class="mb-2">In the absence of any negligence or other breach of duty by us:</p>
								<ul class="list-disc my-5 ml-5">
									<li class="mb-2">
										Your use of our equipment, facility and participation in any activity while at The Wave is
										entirely at your own risk.
									</li>
									<li class="mb-2">
										We are not responsible for any theft, damage, destruction or loss of your property or
										belongings while using our facilities.
									</li>
									<li class="mb-2">
										We accept no liability for any direct or indirect loss, damage or injury arising from or in
										connection with your participation in any activity at The Wave.
									</li>
								</ul>
							</li>
						</ul>
						<h3>The Wave Safety Rules:</h3>
						<ul class="list-disc my-5 ml-5">
							<li class="mb-2">
								You must behave sensibly and follow all safety instructions so as not to hurt or injure
								yourself or others.
							</li>
							<li class="mb-2">
								We can provide you with the equipment you need to safely enjoy your surf session. It is your
								responsibility to use all equipment safely and as directed.
							</li>
							<li class="mb-2">
								You must attend the safety briefing before participating in any activity in the lake and raise
								any concerns or lack of understanding with The Wave Staff.
							</li>
							<li class="mb-2">
								You must read the session descriptions and respect the ability gradings, even if you have
								taken part in a session before. We make ongoing changes and improvements to our sessions, so
								they may have changed since your last visit. You must not take part in a session that is
								beyond your current level of ability.
							</li>
							<li class="mb-2">
								You must be aware of the varying ability of other people in the lake. Be friendly, courteous
								and respectful of other people. Poor conduct will not be tolerated.
							</li>
							<li class="mb-2">
								We strongly recommend that you do not participate in any surfing session at The Wave if you
								are pregnant as it may cause harm to you or your unborn child. If you are pregnant and choose
								to participate, you do so at your own risk.
							</li>
							<li class="mb-2">
								You must not participate if you are under the influence of alcohol or non-prescription drugs
								or if you are taking any medication that may impair your physical ability.
							</li>
							<li class="mb-2">
								You must follow all safety warnings or instructions displayed or given to you by a member of
								The Wave Staff. Please ask a member of The Wave Staff if you are unclear.
							</li>
							<li class="mb-2">You must be able to swim at least 25 metres unaided.</li>
							<li class="mb-2">
								We are not qualified to express an opinion that you are fit to participate safely. If you
								suffer from any medical condition, illness or injury which may affect your ability to
								participate in surfing, bodyboarding or any other activities at The Wave, we recommend you
								obtain professional or specialist advice from your doctor and contact our Customer Services
								Team to discuss your specific requirements.
							</li>
							<li class="mb-2">
								You agree to comply fully with the rules set out in our Terms and Conditions of Booking and
								Access.
							</li>
							<li class="mb-2 font-bold">
								If your ability to participate safely is affected or changes at any time after completing the
								waiver you must notify a member of The Wave Staff.
							</li>
						</ul>
						<h3>Photography</h3>
						<p class="mb-2">
							We may take photographs or recordings during your visit to The Wave where you may be captured.
							You agree to the publication of such photographs and recordings, and their use by us and those
							authorised by us, in any way which we may see fit. This includes making photographs or
							recordings of surf or bodyboarding sessions available for purchase by you, via download from our
							website. If you do not wish to be filmed or photographed during your visit, including during
							your surf or bodyboarding session, please let a member of The Wave Staff know in advance.
						</p>
						<hr />
						<br />
						<div
							class="mb-10"
							v-if="
								$moment().diff(
									$moment(surfer.dateOfBirth, 'DD/MM/YYYY'),
									'years'
								) >= 18
							"
						>
							<p class="font-bold">I confirm that:</p>
							<ul class="list-disc my-5 ml-5">
								<li class="mb-2">I will be 18 years or older on the date of my booked activity.</li>
								<li class="mb-2">
									I have read and understood The Wave Safety Rules and agree to follow them and the instructions
									of The Wave Staff during my booked activity and while on site.
								</li>
								<li class="mb-2">
									I have read and accept this Acknowledgement of Risk and Waiver. I confirm that to the best of my
									knowledge I am fit to take part in the booked activity and acknowledge and accept the risks of
									such participation. If at any time my ability to take part is affected, I will cease
									participation and notify a member of The Wave Staff.
								</li>
								<li>
									I understand that The Wave may take photographs or recordings where I may be captured, including
									photographs or recordings of my surfing or bodyboarding session. I accept that these photographs
									or recordings may be available to purchase and download via The Wave website. I agree that if I
									do not wish to be filmed or photographed, I will let a member of The Wave Staff know in advance
									of my session.
								</li>
							</ul>
							<p class="font-bold">
								Name Of Participant: {{ surfer.firstName }}
								{{ surfer.lastName }}
							</p>
						</div>
						<div
							class="mb-10"
							v-if="
								$moment().diff(
									$moment(surfer.dateOfBirth, 'DD/MM/YYYY'),
									'years'
								) < 18
							"
						>
							<p class="font-bold">I confirm that:</p>
							<ul class="list-disc my-5 ml-5">
								<li class="mb-2">
									I have read and understood The Wave Safety Rules. I will ensure that the participant understands
									and follows The Wave Safety Rules and the instructions of The Wave Staff during their booked
									activity and while on site.
								</li>
								<li class="mb-2">
									I have read and accept this Acknowledgement of Risk and Waiver. I confirm that the participant
									is able to participate in the booked activity and I acknowledge and accept the risks of such
									participation. If at any time the participant's ability to participate is affected, they will
									cease.
								</li>
								<li class="mb-2">
									I confirm that I am 18 years or older and that either (i) I am the parent or legal guardian of
									the participant, or (ii) if I am not the parent or legal guardian of the participant, I have the
									authority of the parent or legal guardian of the participant to sign this Acknowledgement of
									Risk and Waiver on their behalf. <br />
									I understand that The Wave may take photographs or recordings where the participant may be
									captured, including photographs or recordings during a surfing or bodyboarding session. I accept
									that these photographs or recordings may be available to purchase and download via The Wave
									website. I agree that if I do not wish the participant to be filmed or photographed, I will let
									a member of The Wave Staff know in advance of my session.
								</li>
							</ul>
							<p class="font-bold">
								Name Of Participant: {{ surfer.firstName }}
								{{ surfer.lastName }}
							</p>
						</div>
					</div>
					<!--footer-->
					<div
						class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b"
					>
						<button
							class="bg-trasnparent hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full mr-2"
							type="button"
							style="transition: all .15s ease"
							@click="close"
						>
							Close
						</button>
						<button
							class="bg-pear-500 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
							type="button"
							style="transition: all .15s ease"
							@click="acceptWaiver"
						>
							Accept Waiver
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="show" class="opacity-25 fixed inset-0 z-30 bg-black"></div>
	</div>
</template>
<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			},
			close: {
				type: Function
			},
			surfer: {
				type: Object
			},
			acceptWaiver: {
				type: Function
			}
		}
	};
</script>
