<template>
	<div>
		<div
			v-if="show"
			class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex my-5"
		>
			<div class="relative w-auto my-6 mx-auto max-w-6xl h-full">
				<!--content-->
				<div
					class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
				>
					<!--header-->
					<div
						class="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t"
					>
						<h3 class="text-3xl font-semibold">
							Paper Waiver
						</h3>
						<button
							class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
							v-on:click="close"
						>
							<span
								class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
							>
								×
							</span>
						</button>
					</div>
					<!--body-->
					<div class="relative p-6 flex-auto">
						<p class="mb-2 font-bold">
							Please confirm that you want to Sign a Paper Waiver,
							signifying that the surfer has signed a waiver in-person.
						</p>
					</div>
					<!--footer-->
					<div
						class="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b"
					>
						<button
							class="bg-trasnparent hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full mr-2"
							type="button"
							style="transition: all .15s ease"
							@click="close"
						>
							Close
						</button>
						<button
							class="bg-pear-500 hover:bg-pear-700 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
							type="button"
							style="transition: all .15s ease"
							@click="acceptPaperWaiver"
						>
							Accept Paper Waiver
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="show" class="opacity-25 fixed inset-0 z-30 bg-black"></div>
	</div>
</template>
<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			},
			close: {
				type: Function
			},
			acceptPaperWaiver: {
				type: Function
			}
		}
	};
</script>
