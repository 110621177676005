<template>
	<button
		class="relative outline-none bg-white text-gray-400 border-2 border-gray-400 text-center text-xs font-semibold uppercase tracking-widest py-1 lg:py-2 px-3 leading-4 transition-all duration-500 ease-in-out rounded-full cursor-pointer"
		v-bind:class="{
			'pr-8 pl-4': buttonClicked,
			'text-gray-400 hover:text-white border-gray-400 hover:border-gray-900 hover:bg-gray-900': !hasSurferCheckedInHere(
				surfer,
				location
			),
			'text-green-700 hover:text-green-100 border-green-200 hover:border-green-700 bg-green-200 hover:bg-green-700': hasSurferCheckedInHere(
				surfer,
				location
			)
		}"
		v-on:click="arriveSurferAtLocation(surfer, location)"
		type="button"
	>
		{{ location.name == "SurfOps" ? "Surf Desk" : location.name }}
		<span
			class="loader animation-spin h-3 w-3 bg-transparent absolute opacity-0 box-border transition-all duration-500 ease-in-out mx-auto rounded-full"
			v-bind:class="{ 'opacity-100': buttonClicked }"
		></span>
	</button>
</template>

<script>
	import { mapState, mapActions } from "vuex";

	export default {
		props: {
			surfer: {
				type: Object,
				default: () => {},
				required: false
			},
			location: {
				type: Object,
				default: () => {},
				required: false
			},
			activeDate: {
				type: Number,
				default: () => {},
				required: false
			}
		},
		data: () => ({
			buttonClicked: false
		}),
		// Computed Properties:
		computed: {
			...mapState("surfers", [
				"creatingSurfersCheckInLoading",
				"creatingSurfersCheckInSuccess"
			])
		},
		// Component Methods:
		methods: {
			// Mapped Store Actions
			...mapActions([
				"surfers/createSurferCheckIn",
				"surfers/destroySurferCheckIn"
			]),
			// Wave Check In Button Specific Functionality
			arriveSurferAtLocation(surfer, location) {
				let confirmationSurferCheckInText;

				const hasSurferCheckedInHere = this.hasSurferCheckedInHere(
					surfer,
					location
				);

				confirmationSurferCheckInText = `Check ${
					hasSurferCheckedInHere ? "Out" : "In"
				} ${surfer.firstName} ${surfer.lastName} at ${
					location.name
				}. Are you sure?`;

				const confirmSurferArrived = confirm(
					confirmationSurferCheckInText
				);

				this.buttonClicked = confirmSurferArrived;

				if (confirmSurferArrived) {
					if (!hasSurferCheckedInHere) {
						this["surfers/createSurferCheckIn"]({
							surfWaiver: surfer.uuid,
							location: location.uuid,
							activeDate: new Date(this.activeDate).toISOString()
						}).then(() => {
							this.buttonClicked = false;
						});
					}

					if (hasSurferCheckedInHere) {
						const arrival = surfer.checkins.find(
							checkin => checkin.location === location.uuid
						);

						this["surfers/destroySurferCheckIn"]({
							uuid: arrival.uuid,
							activeDate: new Date(this.activeDate).toISOString()
						}).then(() => {
							this.buttonClicked = false;
						});
					}
				}
			},
			hasSurferCheckedInHere(surfer, location) {
				return surfer.checkins.filter(
					checkin => checkin.location === location.uuid
				).length;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.loader {
		// Off-Piste Un-Tailwind-esque Bodges
		right: 0.875rem;
		top: 50%;
		// Loader Construction Using Borders
		border-top: 2px solid #fff;
		border-left: 2px solid #fff;
		border-bottom: 2px solid #fff;
		border-right: 2px solid rgba(#fff, 0.35);
	}

	.animation-spin {
		animation-name: spin;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes spin {
		from {
			transform: translateY(-50%) rotate(0deg);
		}
		to {
			transform: translateY(-50%) rotate(360deg);
		}
	}
</style>
