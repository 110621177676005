var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"relative outline-none bg-white text-gray-400 border-2 border-gray-400 text-center text-xs font-semibold uppercase tracking-widest py-1 lg:py-2 px-3 leading-4 transition-all duration-500 ease-in-out rounded-full cursor-pointer",class:{
		'pr-8 pl-4': _vm.buttonClicked,
		'text-gray-400 hover:text-white border-gray-400 hover:border-gray-900 hover:bg-gray-900': !_vm.hasSurferCheckedInHere(
			_vm.surfer,
			_vm.location
		),
		'text-green-700 hover:text-green-100 border-green-200 hover:border-green-700 bg-green-200 hover:bg-green-700': _vm.hasSurferCheckedInHere(
			_vm.surfer,
			_vm.location
		)
	},attrs:{"type":"button"},on:{"click":function($event){return _vm.arriveSurferAtLocation(_vm.surfer, _vm.location)}}},[_vm._v(" "+_vm._s(_vm.location.name == "SurfOps" ? "Surf Desk" : _vm.location.name)+" "),_c('span',{staticClass:"loader animation-spin h-3 w-3 bg-transparent absolute opacity-0 box-border transition-all duration-500 ease-in-out mx-auto rounded-full",class:{ 'opacity-100': _vm.buttonClicked }})])
}
var staticRenderFns = []

export { render, staticRenderFns }